<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapState } from "vuex";

export default {
  name: "QForMeWaitersIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("qforme", {
      contents: state => state.waiters
    })
  },

  methods: {
    initContent() {
      return {
        queue_id: "",
        transfer_phone_number: "",
        waiter_uuid: "",
        waiter_status: "",
        waiter_display_number: "",
        waiter_display_name: "",
        max_dial_attempt: "",
        current_dial_attempt: "",
        caller_info: ""
      };
    }
  },

  mounted() {
    this.contentType = "QforMe Waiter";
    this.primaryKey = "waiter_id";
    this.scope = "ac";
  }
};
</script>

<style lang="scss" scoped></style>
